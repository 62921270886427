import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

function Services({ className }) {
  return (
    <div className={className}>
      <div className="services-sidebar">
        <h2 className="top">Our Services:</h2>
        <ul>
          <li>
            <Link to="/services/personal-care/">Personal Care</Link>
          </li>
          <li>
            <Link to="/services/medication/">Medication</Link>
          </li>
          <li>
            <Link to="/services/shopping/">Shopping</Link>
          </li>
          <li>
            <Link to="/services/food-preparation/">Food Preparation</Link>
          </li>
          <li>
            <Link to="/services/housework/">Housework</Link>
          </li>
          <li>
            <Link to="/services/overnight-care/">Overnight Care</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

function GoogleMaps({ className }) {
  const [isMounted, setMounted] = useState(true);
  // useEffect(() => setMounted(true));

  const iframe = (
    <iframe
      defer
      title="QCL Map"
      className={className}
      width="100%"
      height="500"
      frameBorder="0"
      scrolling="no"
      marginHeight="0"
      marginWidth="0"
      src="https://maps.google.com/maps?q=Quality+Caring+Ltd,+TW4+6DR,+United+Kingdom&amp;hl=en&amp;sll=51.458713,-0.400658&amp;sspn=0.208996,0.528374&amp;hq=Quality+Caring+Ltd,&amp;hnear=Hounslow+TW4+6DR,+United+Kingdom&amp;t=m&amp;z=17&amp;t=p&amp;z=15&amp;output=embed&amp;iwloc=near"
    />
  );

  const iframe2 = (
    <iframe
      className={className}
      title="QCL Map"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9942.557997029697!2d-0.400971!3d51.464775!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf9766b44f68f1dd9!2sQuality%20Caring%20Ltd!5e0!3m2!1sen!2sus!4v1603456634519!5m2!1sen!2sus"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen=""
      aria-hidden="false"
    />
  );

  const placeholder = (
    <img
      className={className}
      alt="QCL Map"
      src="https://maps.googleapis.com/maps/api/staticmap?center=Berkeley,CA&zoom=14&size=400x400&key=AIzaSyCl0iT5fcA0Kww-P52KOWsDYdWJ1ezodZQ"
    />
  );

  return isMounted ? iframe2 : placeholder;
}

export default {
  Services,
  GoogleMaps,
};
