import React from "react";
import ServicePage from "../../components/ServicePage";
import PageMeta from "../../components/PageMeta";

export default function MedicationPage() {
  return (
    <ServicePage>
      <PageMeta title="QCL Services - Medication" path="services/medication" />
      <h1 className="top">Medication</h1>
      <p className="lead">
        Many Service Users regularly take prescribed medication.
      </p>
      <p>QCL have identified the following five “medication” categories :-</p>
      <ul>
        <li>User takes no medication.</li>
        <li>
          User takes medication and consistently manages the administration of
          said medication his/herself.
        </li>
        <li>
          User takes medication and consistently relies on family member/s or
          nominated person (other than QCL employee) to administer his/her
          medication.
        </li>
        <li>
          User takes medication and requires the occasional “prompt” or
          “assistance” from QCL.
        </li>
        <li>
          User takes medication and requires QCL to take responsibility for the
          administration of said medication.
        </li>
      </ul>
      <p className="lead">Each Service User is carefully assessed.</p>
      <p>
        If a Service User is assessed as category 1,2 or 3, QCL will not be
        involved in his/her potential medication requirements.
      </p>
      <p>
        A Service User assessed as category 4 will receive “low level”
        assistance that will be recorded in the Care Plan.
      </p>
      <p>
        A Service User assessed as category 5 will receive a full “Medication
        Administration” service via a Medication Administration Record (MAR
        Chart).
      </p>
      <p>
        The MAR chart will be placed in the Service User’s home and details of
        all medication given will be recorded.
      </p>
      <p>
        Our carers are trained to “Medication Administration” standards as
        required by Local Authorities and the NHS.
      </p>
      <p>
        WE DO NOT PROVIDE NURSING SERVICES AND OUR CARERS ARE NOT AUTHORISED TO
        PRESCRIBE MEDICATION.
      </p>
      <p>
        We do, however, take instructions from (and work very closely with) GPs,
        Hospital Staff and Social Services to ensure that all medication
        delivery complies with relevant regulations.
      </p>
      <p>
        Completed MAR charts are made available for scrutiny by authorised
        persons in line with our commitment to delivering safe, relevant and
        transparent service.
      </p>
      <p>
        For further information, please read the{" "}
        <a href="/pdf/QCL-Medication-Policy.pdf">QCL Medication Policy</a>.
      </p>
    </ServicePage>
  );
}
