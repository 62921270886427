import React from "react";
import Fragments from "./Fragments";

export default function ServicePage({ children }) {
  return (
    <>
      <div className="col3 t-col2">{children}</div>
      <Fragments.Services className="col3 t-col1" />
    </>
  );
}
