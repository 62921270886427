import React from "react";
import { Helmet } from "react-helmet";

export default function PageMeta({ title, path }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${title}`}</title>
      <link
        rel="canonical"
        href={`http://qcl-uk.com/${path.length > 0 ? `${path}/` : ""}`}
      />
    </Helmet>
  );
}
